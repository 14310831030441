.index-page .index-contacts-block {

  .contacts-block-head {
    padding: 20px 0;
    background: #030305;

    .contacts-box2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap;
      width: 100%;

      .item {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 49%;

        .cnt-item {
          display: flex;
          display: -ms-flexbox;
          flex-direction: row;
          -ms-flex-pack: start;
          justify-content: flex-start;
          -ms-flex-align: start;
          align-items: flex-start;
          flex-wrap: nowrap;
          -ms-flex-wrap: nowrap;

          .icon {
            width: 32px;
            height: 32px;
            position: relative;
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            top: 5px;

            img {
              display: block;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              margin: auto;
              width: auto;
              height: auto;
            }
          }

          .text {
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            text-align: left;
            font: 300 20px/1.2 "Roboto", sans-serif;
            padding-left: 43px;
            letter-spacing: 1px;

            p {
              margin: 0;
            }

            a {
              text-decoration: underline;
              transition: all .2s linear;
              color: #8C9ABE;

              &:hover {
                text-decoration-color: transparent;
              }
            }

            @media (max-width: 1200px) {
              font-size: 16px;
            }

            @media (max-width: 700px) {
              h2 {
                font-size: 1.8rem;
              }
            }
          }

          .icon + .text {
            padding-left: 13px;
          }

          &.cnt-phones {
            .text {
              font: 300 36px/1.2 "Roboto", sans-serif;

              a {
                color: #FFF;
                text-decoration: none;

                &:hover {
                  color: #FFF;
                  text-decoration: none;
                }
              }

              @media (max-width: 1200px) {
                font-size: 30px;
              }
            }

            .icon {
              top: 12px;
            }
          }

          &:nth-child(n+2) {
            margin-top: 25px;
          }
        }

        @media (max-width: 700px) {
          width: 100%;
          max-width: 100%;

          &:nth-child(n+2) {
            margin-top: 16px;
          }
        }
      }
    }
  }

  .contacts-block {
    position: relative;

    .map-block {
      position: relative;
      top: 0;
      left: 0;
      width: 100%;
      height: 688px;
      z-index: 0;

      #map {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;

        @media (max-width: 1200px) {
          left: auto;
          right: 0;
        }

        @media (max-width: 700px) {
          width: 100%;
        }
      }

      @media (max-width: 700px) {
        position: relative;
        height: 514px;
        width: 100%;
      }

      @media (max-width: 600px) {
        height: 320px;
      }
    }
  }
}